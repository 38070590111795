<template>
  <div>
    <div class="flex items-center mb-5">
      <h3 class="uppercase text-lg font-semibold text-primary tracking-wide">
        Account Information
      </h3>
      <hr class="flex-1 border-primary mx-4" />
      <!-- <button
        class="flex items-center bg-blue-200 border hover:bg-blue-300 border-blue-400 hover:border-blue-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
      >
        <svg
          class="stroke-current h-5 w-5 mr-2"
          fill="none"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          viewBox="0 0 24 24"
        >
          <path
            d="M19 21H5a2 2 0 01-2-2V5a2 2 0 012-2h11l5 5v11a2 2 0 01-2 2z"
          />
          <path d="M17 21v-8H7v8M7 3v5h8" />
        </svg>
        <span>Update Details</span>
      </button> -->
    </div>
    <div class="flex flex-wrap -my-3 -mx-5">
      <div class="flex flex-col w-full sm:w-1/2 lg:w-1/3 my-3 px-5">
        <label class="uppercase font-semibold text-sm">Agent</label>
        <div v-if="contactSelectOpen" @click="contactSelectOpen = false" class="absolute inset-0"></div>
        <div class="relative mx-3">
          <div class="relative -mx-3">
            <input
              readonly
              @click="openSearchContact"
              v-model="contact.agent_name"
              class="w-full bge-input py-2 pl-4 pr-10 rounded cursor-pointer"
              type="text"
            />
            <button
              v-if="contact.agent_name"
              name="Clear Supplier"
              @click="clearContact"
              class="m-1 z-10 text-red-500 hover:bg-red-200 rounded-full absolute inset-y-0 right-0 flex items-center px-2 transition-colors duration-150 ease-in-out"
            >
              <svg class="stroke-current h-5 w-5" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" viewBox="0 0 24 24">
                <path d="M18 6L6 18M6 6l12 12" />
              </svg>
            </button>
          </div>
          <div v-if="contactSelectOpen" class="z-10 absolute w-full max-h-5vh bg-white shadow-lg rounded border border-gray-300 top-0 mt-5 p-2">
            <div class="relative flex flex-col">
              <input
                ref="tbSupplierSearch"
                type="text"
                v-model="contactSearchTerm"
                @keydown="searchContact"
                class="bge-input bge-input-spacing rounded"
                placeholder="Contact name (enter 3 letters or more to search)"
              />
              <div class="flex flex-col overflow-y-scroll bg-white mt-2">
                <template v-for="result in contactResults">
                  <button
                    type="button"
                    :key="result.contact_id"
                    @click="selectContact(result)"
                    class="hover:bg-orange-200 flex items-center py-2 transition duration-100 focus:bg-orange-200 text-left px-2"
                  >
                    <span class="ml-1">{{ result.name }}</span>
                  </button>
                </template>
                <p class="mx-auto py-4 px-8" v-if="contactResults == ''">
                  No Agents match your search
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-col w-full sm:w-1/2 lg:w-1/3 my-3 px-5">
        <label class="uppercase font-semibold text-sm">VTS No</label>
        <input v-model="contact.vts_number" class="bge-input bge-input-spacing rounded" type="text" />
      </div>
      <div class="flex flex-col w-full sm:w-1/2 lg:w-1/3 my-3 px-5">
        <label class="uppercase font-semibold text-sm">Customer Ref No</label>
        <input v-model="contact.account_reference" class="bge-input bge-input-spacing rounded" type="text" />
      </div>
      <div class="flex flex-col w-full sm:w-1/2 lg:w-1/3 my-3 px-5">
        <label class="uppercase font-semibold text-sm">VAT No</label>
        <input v-model="contact.vat_number" class="bge-input bge-input-spacing rounded" type="text" />
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  name: "AccountsInformation",
  props: {
    contact: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      contactSearchTerm: "",
      contactResults: [],
      contactSelectOpen: false,
    };
  },
  mounted() {
    this.selectedAgent = this.contact.agent || {};
  },
  methods: {
    openSearchContact: function() {
      this.contactSearchTerm = "";
      this.contactResults = [];
      this.contactSelectOpen = true;
      this.$nextTick(function() {
        this.$refs.tbSupplierSearch.focus();
      });
    },
    searchContact: _.debounce(async function() {
      this.contactResults = [];
      if (this.contactSearchTerm != "" && this.contactSearchTerm.length >= 3) {
        let results = await this.ContactService.searchContact(this.contactSearchTerm);
        this.contactResults = results.data;
      }
    }, 500),
    selectContact: function(contact) {
      this.contact.agent_id = contact.contact_id;
      this.contact.agent_name = contact.name;
      this.contactSelectOpen = false;
    },
    clearContact: function() {
      this.contact.agent_id = "";
      this.contact.agent_name = "";
      this.$forceUpdate();
    },
  },
};
</script>
